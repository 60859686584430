import ApiService from "@/core/services/ApiService";

class HTTPService {
  public static post(urn: string, data: object) {
    ApiService.events();
    return ApiService.httpRequest.post(urn, data);
  }

  public static patch(urn: string, data: object) {
    ApiService.events();
    return ApiService.httpRequest.patch(urn, data);
  }

  public static uploadFile(urn: string, data: object) {
    ApiService.events();
    return ApiService.httpRequest.post(urn, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  public static get(urn: string, paramsRequest = {}) {
    ApiService.events();
    return ApiService.httpRequest.get(urn, { params: paramsRequest });
  }

  public static delete(urn: string, paramsRequest = {} as any) {
    ApiService.events();

    return ApiService.httpRequest.post(urn, {
      params: paramsRequest,
      _method: "delete",
    });
  }

  public static isValid(response) {
    return response !== null && typeof response !== "undefined";
  }

  public static serverError() {
    return { 0: "Ошибка сервера, обратитесь в службу поддержки!" };
  }
}

export default HTTPService;
