import HTTPService from "@/core/services/HTTPService";
// import { AxiosResponse } from "axios";
import { createStore, useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import AuthModule from "@/store/modules/AuthModule";
import { Mutations } from "@/store/enums/StoreEnums";

class AuthService {
  public static async me() {
    const store = createStore({
      modules: {
        AuthModule,
      },
    });

    if (AuthService.getToken().length > 0) {
      await HTTPService.post("me", {})
        .then(({ data }) => {
          if (
            typeof data.data === "undefined" ||
            typeof data.data.id === "undefined"
          ) {
            store.commit(Mutations.SET_ERROR, HTTPService.serverError());

            store.commit(Mutations.PURGE_AUTH, data);
          } else {
            store.commit(Mutations.SET_AUTH, data.data);
          }
        })
        .catch(({ response }) => {
          //
        });
    }
  }

  public static removeToken() {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
  }

  public static getToken(): string {
    return localStorage.getItem("token") ?? "";
  }

  /*
  login(email: string, password: string) {
    const urn = "/auth/login";
    const data = { email, password };

    return ApiService.httpRequest.post(urn, data);
  }

  logout() {
    const urn = "/auth/logout";
    return ApiService.httpRequest.post(urn);
  }

  signup(
    email: string,
    password: string,
    passwordConfirm: string,
    name: string
  ) {
    const urn = "/auth/signup";
    const data = { email, password, passwordConfirm, name };

    return ApiService.httpRequest.post(urn, data);
  }

  isAuthorized(): boolean {
    return this.getToken().length > 0;
  }

  getToken(): string {
    return localStorage.getItem("token") ?? "";
  }

  getEmail(): string {
    return localStorage.getItem("email") ?? "";
  }

  showMenu() {
    return this.isAuthorized();
  }

  setToken() {
    ApiService.createRequestHandler();
  }



  updateLocalStorage(responseServer: AxiosResponse, email: string) {
    if (
      responseServer.data.data &&
      typeof responseServer.data.data.accessToken !== "undefined"
    ) {
      localStorage.setItem("token", responseServer.data.data.accessToken);
      localStorage.setItem("email", email);

      const sessionBackUrl = sessionStorage.getItem("backUrl");

      return sessionBackUrl !== null ? sessionBackUrl : "/";
    }

    return null;
  }

  hideNonAuthSection(): boolean {
    const currentName = router.currentRoute.value.name;
    return currentName == "login" || currentName == "signup";
  }*/
}

export default AuthService;
